body {
  margin:0;
  padding:0;
  background-color: blue;
  background-image: url('/src/assets/back_mobile.jpg');
  background-size: auto calc(100vh * 4);
  background-position: center top;
  background-repeat: no-repeat;
}
/* Estilo padrão para dispositivos desktop (largura mínima de 1024px) */
@media (min-width: 1024px) and (orientation: landscape) {
  body {
    /* background-image: url('/src/assets/back_desktop_t.png'); */
    /* background-size: 100vw auto; */
    /* background-size: auto 400vh; */
    background-size: cover;
    background-color: #182547;
    background-position: center top;
    background-repeat: no-repeat;
  }
  
  .App {
    display: grid;
    background-image: url('/src/assets/back_desktop.jpg');
    background-size: auto 400vh;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-position: center top;
    max-width: 100%;
    grid-template-columns: 50vw 50vw;
    grid-template-rows: 100vh 100vh 100vh 100vh;
    padding:0;
    margin:0;
    /* height: 100vh; */
  } 

  .FormContainerStaticVideo {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
    justify-self: center;
    align-self: end;
    width: clamp(1024px, 84vw, 1290px);
    height: clamp(440px, 30.5vw, 510px);
    border: none;
    border-radius: 20px;
    background-color: #d6d465;
    padding: 15px;
    opacity: 1;
    overflow: hidden;
  }

  .App > .VideoPlayer {
    display: none;
  }

  .FormContainerStaticVideo .VideoPlayer {
    width:71%;
    height:100%;
  }

  .FormVideoContainer{
    width:29%;
  }


  .FormVideoContainer .Buttons {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height:clamp(62px, 7.5vw, 76px);
  }
  
  .FormVideoContainer .submit-btn {
    display:block;
    align-self: center;
    background-color: #267400;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight:600;
    cursor: pointer;
    width:calc(100% - 60px);
    height:100%;
  }

  .FormVideoContainer .whats-btn {
    display:inline-block;
    background-color: #06aa02;
    color: white;
    margin-left: 10px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    width:clamp(62px, 7.5vh, 76px);
    height:clamp(62px, 7.5vh, 76px);
  }

  .FormContainerStatic {
    /* position: fixed; */
    justify-self: center;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
    width: 24vw;
    max-width: 550px;
    min-width: 300px;
    height: 46.5vh;
    border: none;
    border-radius: 20px;
    background-color: #d6d465;
    padding: 15px;
    opacity: 1;
    overflow: hidden;
  }
  
  .FormContainerStatic .Buttons {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height:clamp(62px, 7.5vh, 76px);
  }
  
  .FormContainerStatic .submit-btn {
    display:block;
    align-self: center;
    background-color: #267400;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight:600;
    cursor: pointer;
    width:calc(100% - 60px);
    height:100%;
  }
  
  .FormContainerStatic .whats-btn {
    display:inline-block;
    background-color: #06aa02;
    color: white;
    margin-left: 10px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    width:clamp(62px, 7.5vh, 76px);
    height:clamp(62px, 7.5vh, 76px);
  }
  
  .formContainerOne {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    margin-top: 42.8vh;
    margin-left: 16.5vw;
    justify-self: start;
    align-self: start;
  }

  .formContainerFour {
    display: none;
    grid-row-start: 4;
    grid-row-end: 5;
    margin-top: 45vh;
    align-self: start;
  }

  .formContainerFive {
    grid-row-start: 4;
    grid-row-end: 5;
    margin-top: 45vh;
    align-self: start;
  }


  .FormContainer {
    align-self: end;
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 26vw;
    max-width: 420px;
    min-width: 320px;
    height: 46.5vh;
    border: none;
    border-radius: 20px;
    background-color: #d6d465;
    padding: 15px;
    opacity: 1;
    overflow: hidden;
  }
  
  .FormContainer .Buttons {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 7.5vh;
  }
  
  .FormContainer .submit-btn {
    background-color: #267400;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight:600;
    cursor: pointer;
    width:calc(100% - 77px);
    height:100%;
  }
  
  .FormContainer .whats-btn {
    display:inline-block;
    background-color: #06aa02;
    color: white;
    margin-left: 15px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    width:clamp(62px, 7.7vh, 76px);
    height:clamp(62px, 7.7vh, 86px);
  }
  
  .spoiler {
    height: 10.6vh;
    animation: 0.3s ease-out 0s 1 normal retract;
  }
  
  .expand {
    animation: 0.3s ease-out 0s 1 normal expand;
  }
  
  @keyframes expand {
    from {
      height: 10.6vh;
    }
    to {
      height: 46.5vh;
    }
  }
  @keyframes retract {
    from {
      height: 46.5vh;
    }
    to {
      height: 10.6vh;
    }
  }

  .formContainerTwo {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-bottom: 13vh;
    margin-right: 10vw;
    align-self: end;
    justify-self: end;
  }

  .formContainerThree {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    margin-bottom: 5vh;
    align-self: end;
  }

  .Title {
    font-size: 2vh;
    font-weight: 900;
    text-transform: uppercase;
    color: #00187c;
    margin-bottom: 8px;
    text-align: center;
    line-height: 110%;
  }

  .formulario {
    display:flex;
    flex-direction: column;
    margin-bottom: 14px;
    row-gap: 1vh;
    /* height: 65%; */
    /* border: 1px solid black; */
    border-radius: 8px;
  }
  
  .form-group {
    /* border: 1px solid black; */
    height: 5vh;
  }
  
  .input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 2vh;
    text-indent: 6px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .input::placeholder {
    color: #666;
  }

    .input:disabled{
    color: #fff;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
}
  
  /* Estilo para tablets (largura mínima de 768px e máxima de 1023px) */
@media (orientation: portrait) and (min-device-width: 611px) and (max-device-width: 1024px) {
  body {
    background-image: url('/src/assets/back_tablet2.jpg');
  }
  
  .App {
    display: grid;
    max-width: 100%;
    grid-template-columns: 100vw;
    grid-template-rows: 100vh 100vh 100vh 100vh;
    padding:0;
    margin:0;
  } 

  .FormContainerStaticVideo {
    display: none;
  }

  .VideoPlayer {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
    align-self: start;
    margin-top:10px
  }

  .FormContainerStatic {
    /* position: fixed; */
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 60vw;
    max-width: 550px;
    min-width: 300px;
    height: 470px;
    border: none;
    border-radius: 20px;
    background-color: #d6d465;
    padding: 15px;
    opacity: 1;
    overflow: hidden;
  }
  
  .FormContainerStatic .Buttons {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height:76px;
  }
  
  .FormContainerStatic .submit-btn {
    display:block;
    align-self: top;
    background-color: #267400;
    color: white;
    /* padding: 10px 15px; */
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight:600;
    cursor: pointer;
    width:calc(100% - 60px);
    height:70px;
  }
  
  .FormContainerStatic .whats-btn {
    display:inline-block;
    background-color: #06aa02;
    color: white;
    margin-left: 10px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    max-width:70px;
    height:70px;
  }
  
  .formContainerOne {
    grid-row-start: 1;
    grid-row-end: 2;
    margin-top: 52vh;
    align-self: start;
  }

  .formContainerFour {
    grid-row-start: 4;
    grid-row-end: 5;
    margin-top: 50vh;
    align-self: start;
  }


  .FormContainer {
    /* position: fixed; */
    align-self: end;
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 60vw;
    max-width: 550px;
    min-width: 300px;
    height: 470px;
    border: none;
    border-radius: 20px;
    background-color: #d6d465;
    padding: 15px;
    opacity: 1;
    overflow: hidden;
  }
  
  .FormContainer .Buttons {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height:70px;
  }
  
  .FormContainer .submit-btn {
    background-color: #267400;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight:600;
    cursor: pointer;
    width:calc(100% - 77px);
    height:70px;
  }
  
  .FormContainer .whats-btn {
    display:inline-block;
    background-color: #06aa02;
    color: white;
    margin-left: 15px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    max-width:70px;
    height:70px;
  }
  
  .spoiler {
    height: 98px;
    animation: 0.3s ease-out 0s 1 normal retract;
  }
  
  .expand {
    animation: 0.3s ease-out 0s 1 normal expand;
  }
  
  @keyframes expand {
    from {
      height: 98px;
    }
    to {
      height: 470px;
    }
  }
  @keyframes retract {
    from {
      height: 470px;
    }
    to {
      height: 98px;
    }
  }

  .formContainerTwo {
    grid-row-start: 2;
    grid-row-end: 3;
    margin-bottom: 10px;
    align-self: end;
  }

  .formContainerThree {
    grid-row-start: 3;
    grid-row-end: 4;
    margin-bottom: 15px;
    align-self: end;
  }

  .Title {
    font-size: 26px;
    font-weight: 900;
    text-transform: uppercase;
    color: #00187c;
    margin-bottom: 8px;
    text-align: center;
    line-height: 110%;
  }

  .formulario {
    display:flex;
    flex-direction: column;
    margin-bottom: 8px;
    row-gap: 6px;
    /* height: 65%; */
    /* border: 1px solid black; */
    border-radius: 8px;
  }
  
  .form-group {
    /* border: 1px solid black; */
    height: 55px;
  }
  
  .input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 18px;
    text-indent: 6px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .input::placeholder {
    color: #666;
  }

    .input:disabled{
    color: #fff;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
}
  
  /* Estilo para dispositivos móveis (largura máxima de 767px) */
@media (max-device-width: 610px) {
  body {
    margin:0;
    padding:0;
    background-color: blue;
    background-image: url('/src/assets/back_mobile.jpg');
    background-size: auto calc(100vh * 4);
    background-position: center top;
    background-repeat: no-repeat;
  }
  
  .App {
    display: grid;
    max-width: 100%;
    grid-template-columns: 100vw;
    grid-template-rows: 100vh 100vh 100vh 100vh;
    padding:0;
    margin:0;
  }  

  .FormContainerStaticVideo {
    display: none;
  }

  .VideoPlayer {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
    align-self: flex-start;
    margin-top:10px
  }

  .FormContainerStatic {
    /* position: fixed; */
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 84vw;
    max-width: 430px;
    min-width: 300px;
    height: 320px;
    border: none;
    border-radius: 20px;
    background-color: #d6d465;
    padding: 15px;
    opacity: 1;
    overflow: hidden;
  }
  
  .FormContainerStatic .Buttons {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height:62px;
  }
  
  .FormContainerStatic .submit-btn {
    display:block;
    align-self: center;
    background-color: #267400;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight:600;
    cursor: pointer;
    width:calc(100% - 60px);
    height:62px;
  }
  
  .FormContainerStatic .whats-btn {
    display:inline-block;
    background-color: #06aa02;
    color: white;
    margin-left: 10px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    max-width:62px;
    height:62px;
  }
  
  .formContainerOne {
    grid-row-start: 1;
    grid-row-end: 2;
    margin-top: 52.5vh;
    align-self: start;
  }

  .formContainerFour {
    grid-row-start: 4;
    grid-row-end: 5;
    margin-top: 52vh;
    align-self: start;
  }


  .FormContainer {
    /* position: fixed; */
    align-self: end;
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 84vw;
    max-width: 430px;
    min-width: 330px;
    height: 320px;
    border: none;
    border-radius: 20px;
    background-color: #d6d465;
    padding: 12px;
    opacity: 1;
    overflow: hidden;
  }
  
  .FormContainer .Buttons {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  
  .FormContainer .submit-btn {
    background-color: #267400;
    color: white;
    padding: 10px 10px;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight:600;
    cursor: pointer;
    width:calc(100% - 77px);
    height:55px;
  }
  
  .FormContainer .whats-btn {
    display:inline-block;
    background-color: #06aa02;
    color: white;
    margin-left: 15px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    max-width:55px;
    height:55px;
  }
  
  .spoiler {
    height: 80px;
    animation: 0.3s ease-out 0s 1 normal retract;
  }
  
  .expand {
    animation: 0.3s ease-out 0s 1 normal expand;
  }
  
  @keyframes expand {
    from {
      height: 80px;
    }
    to {
      height: 320px;
    }
  }
  @keyframes retract {
    from {
      height: 320px;
    }
    to {
      height: 90px;
    }
  }

  .formContainerTwo {
    grid-row-start: 2;
    grid-row-end: 3;
    margin-bottom: 10px;
    align-self: end;
  }

  .formContainerThree {
    grid-row-start: 3;
    grid-row-end: 4;
    margin-bottom: 0px;
    align-self: end;
  }


  .Title {
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    color: #00187c;
    margin-bottom: 6px;
    text-align: center;
    line-height: 110%;
  }

  .formulario {
    display:flex;
    flex-direction: column;
    margin-bottom: 6px;
    row-gap: 3px;
    /* height: 65%; */
    /* border: 1px solid black; */
    border-radius: 8px;
  }
  
  .form-group {
    /* border: 1px solid black; */
    height: 35px;
  }
  
  .input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .input::placeholder {
    color: #666;
  }

    .input:disabled{
    color: #fff;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }

  
}
  